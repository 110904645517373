<template>
    <div class="pentagone">
        <div class="aussen">
            <div v-for="i in 5" :key="i"
                :class="{hover: active === i}"
                @mouseenter="mouseenter(i)"
                @mouseleave="mouseleave(i)"
                @click="click(i)">
                <pentagon></pentagon>
                <div class="inhalt">
                    <slot :name="`aussen-${i}`"/>
                </div>
                <div :class="`hover-text aussen-${i}-text`">
                    <slot :name="`aussen-${i}-text`"></slot>
                </div>
            </div>
        </div>
        <div class="innen" @click="clickCenter">
            <pentagon></pentagon>
            <div class="inhalt">
                <slot name="innen"/>
            </div>
        </div>
    </div>
</template>

<script>
import Pentagon from './Pentagon'

export default {
    components: { Pentagon },
    data() {return{
        active: false
    }},
    methods: {
        mouseenter(i) {
            this.active = i
            this.$emit('hover-start', i, this.$el.querySelector(`.aussen-${i}-text`)?.innerHTML || '')
        },
        mouseleave(i) {
            this.active = false
            this.$emit('hover-end', i)
        },
        click(i) {
            this.active = i
            this.$emit('click', i, this.$el.querySelector(`.aussen-${i}-text`)?.innerHTML || '')
        },
        clickCenter() {
            this.active = false
            this.$emit('click-center')
        },
    }
}
</script>

<style lang="scss" scoped>
@use '../styles/colors' as *;

$size: 5em;
$corners: 5;

.pentagone {
    height: 3 * $size;
    position: relative;

    $move-to-center: translate(-50%, -50%);

    .hover-text {
        display: none;
    }

    .aussen>div,
    .innen {
        position: absolute;
        top: 50%;
        left: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        width: $size;
        height: $size;

        > svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }

    .innen {
        transform-origin: center center;
        transform: $move-to-center rotateZ(180deg) translateY(-$size * 0.0485);

        .inhalt {
            max-width: 2.5 * $size;
            overflow: hidden;
            transform: rotateZ(180deg);
        }
    }

    @for $i from 1 through $corners {
        $rotation: ($i - 1) * (360deg / $corners);

        .aussen > :nth-child(#{$i}) {
            transform-origin: center center;

            .inhalt {
                transform: rotateZ(-$rotation);
            }
        }

        &.visible .aussen > :nth-child(#{$i}) {
            transform: $move-to-center rotateZ($rotation) translateY(-$size * 0.88);
            transition: transform .3s;

            &.hover {
                transform: $move-to-center rotateZ($rotation) translateY(-$size * 1.05);
                // transition: transform .3s;
            }

            animation: blend-in-#{$i} .3s (.3s + .3s * $i) backwards;
            @keyframes blend-in-#{$i} {
                from {
                    transform: $move-to-center rotateZ($rotation) translateY(-$size * 1.2);
                }
                to {
                    transform: $move-to-center rotateZ($rotation) translateY(-$size * 0.88);
                }
            }
        }
    }

    .aussen {

        > :nth-child(1) {
            fill: $gelb;
        }

        > :nth-child(2) {
            fill: $himmelblau;
        }

        > :nth-child(3) {
            fill: $beige;
        }

        > :nth-child(4) {
            fill: $rot-dunkel;
        }

        > :nth-child(5) {
            fill: $gruen;
        }
    }
}
</style>